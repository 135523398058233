
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import { Component, Vue } from 'vue-property-decorator';

import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';

import type UserService from '@/modules/user/user.service';
import type HotelsService from '@/modules/hotels/hotels.service';
import type ProvidersService from '@/modules/providers/providers.service';
import UserViewService, { UserViewServiceS } from '@/modules/user/user-view.service';
import RVSScoreService from '../../rvs-score.service';

@Component({
    components: {
        CustomSelect,
    },
})
export default class RVSHeader extends Vue {
    @inject(KEY.UserService) private userService!: UserService;
    @inject(KEY.HotelsService) private hotelsService!: HotelsService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;
    @inject(KEY.RVSScoreService) private rvsScoreService!: RVSScoreService;
    @inject(UserViewServiceS) private userViewService!: UserViewService;

    get provider() {
        return this.rvsScoreService.settings.provider;
    }

    set provider(value: string | null) {
        this.rvsScoreService.settings.provider = value;
    }

    get providers() {
        return [
            {
                name: this.$tc('All Channels'),
                value: null,
            },
            ...this.providersService.toItemsList(this.providersService.userProviders.rateProviders, true),
        ];
    }

    get isHotelUser() {
        return !this.userService.isChainOrClusterUser;
    }

    get hotelName(): string | null {
        const { currentHotelId = +this.$route.params.hotelId } = this.userService;

        if (!currentHotelId) {
            return null;
        }

        return this.hotelsService.hotelNames[currentHotelId];
    }

    toCluster() {
        this.userViewService.goToClusterPage();
    }
}
